import React from 'react';

import SEO from '../components/seo';
import { Page, CiteTitle } from '../components/styled';

const RepertoirePage = () => (
    <Page>
        <SEO title="Repertoire" pathname="/repertoire" />
        <h1>Repertoire</h1>
        <h2>Solo</h2>
        <ul>
            <li>
                J. S. Bach. Preludes and fugues: Cis-dur, f-moll, b-moll (WTC
                I); A-dur, a-moll, As-dur (WTC II)
            </li>
            <li>J. Haydn. Sonata e-moll XVI:34</li>
            <li>J. Haydn Sonata Es-dur XVI:49</li>
            <li>W. A. Mozart. Sonata F-dur K332</li>
            <li>
                W. A. Mozart. 12 variations on{' '}
                <CiteTitle>La belle Françoise</CiteTitle> K353/300f
            </li>

            <li>L. van Beethoven. Sonata op.&nbsp;10 No.&nbsp;2 F-dur</li>
            <li>L. van Beethoven. Sonata op.&nbsp;22 B-dur</li>
            <li>L. van Beethoven. Sonata op.&nbsp;26 A-dur</li>
            <li>F. Schubert. 4 Impromptus D. 935</li>
            <li>
                R. Schumann. <CiteTitle>Kinderszenen</CiteTitle> op.&nbsp;15
            </li>
            <li>
                R. Schumann. <CiteTitle>Fantasiestücke</CiteTitle> op.&nbsp;12
            </li>
            <li>F. Chopin. Ballade No.&nbsp;1, op.&nbsp;23</li>
            <li>F. Chopin. Etudes op.&nbsp;10 Nos.&nbsp;3, 5, 8, 9, 12</li>
            <li>
                J. Brahms.{' '}
                <CiteTitle>
                    Variationen über ein Thema von Robert Schumann
                </CiteTitle>{' '}
                op.&nbsp;9
            </li>
            <li>
                J. Brahms. <CiteTitle>Zwei Rhapsodien</CiteTitle> op.&nbsp;79
            </li>
            <li>
                J. Brahms. <CiteTitle>Fantasien</CiteTitle> op.&nbsp;116
            </li>
            <li>
                J. Brahms. <CiteTitle>Drei Intermezzi</CiteTitle> op.&nbsp;117
            </li>
            <li>
                C. Debussy. <CiteTitle>Pour le piano</CiteTitle> suite
            </li>
            <li>
                P. Tchaikovsky. <CiteTitle>The Seasons</CiteTitle> op.&nbsp;37b
            </li>
            <li>
                S. Rachmaninoff. <CiteTitle>10 Preludes</CiteTitle> op.&nbsp;23
            </li>
            <li>
                A. Scriabin. <CiteTitle>24 Preludes</CiteTitle> op.&nbsp;11
            </li>
            <li>
                N. Medtner. <CiteTitle>Sonata-Reminiscenza</CiteTitle>{' '}
                op.&nbsp;38 No.&nbsp;1
            </li>
            <li>
                N. Medtner. <CiteTitle>Canzona Matinata</CiteTitle>,{' '}
                <CiteTitle>Sonata Tragica</CiteTitle> op.&nbsp;39 Nos.&nbsp;4, 5
            </li>
        </ul>
        <h2>Orchestral works</h2>
        <ul>
            <li>W. A. Mozart. Piano Concerto No.&nbsp;23 A-dur K488</li>
            <li>R. Schumann. Piano Concerto op.&nbsp;54</li>
            <li>J. Brahms. Piano Concerto No.&nbsp;1 op.&nbsp;15</li>
            <li>
                C. Franck. <CiteTitle>Symphonic Variations</CiteTitle> for piano
                and orchestra
            </li>
            <li>S. Rachmaninoff. Concerto No.&nbsp;1 op.&nbsp;1</li>
        </ul>
        <h2>Piano Duet</h2>
        <ul>
            <li>
                J. Brahms. <CiteTitle>Souvenir de la Russie</CiteTitle> for
                piano four hands
            </li>
            <li>
                M. Moszkowski. <CiteTitle>Deutsche Reigen</CiteTitle>{' '}
                op.&nbsp;25 for piano four hands
            </li>
            <li>
                M. Moszkowski. <CiteTitle>Aus aller Herren Länder</CiteTitle>{' '}
                op.&nbsp;23, suite for piano four hands
            </li>
            <li>
                M. Moszkowski. <CiteTitle>5 Spanish Dances</CiteTitle>{' '}
                op.&nbsp;12 for piano four hands
            </li>
            <li>
                A. Arensky. <CiteTitle>12 Pieces</CiteTitle> op.&nbsp;66 for
                piano four hands
            </li>
            <li>
                S. Rachmaninoff. <CiteTitle>6 Morceaux</CiteTitle> op.&nbsp;11
                for piano four hands
            </li>
        </ul>
        <h2>Ensemble with strings</h2>
        <ul>
            <li>S. Rachmaninoff. Trio elegiaque No.&nbsp;1 g-moll</li>
            <li>
                F. Schubert. Sonata <CiteTitle>Arpeggione</CiteTitle>
            </li>
            <li>
                R. Schumann. Sonata for violin and piano No.&nbsp;2 op.&nbsp;121
                d-moll
            </li>
            <li>C. Franck. Sonata for violin and piano A-dur</li>
            <li>J. Brahms. Sonata for cello and piano op.&nbsp;38 e-moll</li>
            <li>
                P. Tchaikovsky.{' '}
                <CiteTitle>The Variations on Theme a Rococo</CiteTitle> for
                cello and orchestra
            </li>
            <li>
                C. Saint-Saëns. <CiteTitle>La muse et le poète</CiteTitle> in E
                minor for violin, cello and orchestra (or piano)
            </li>
            <li>
                A. Dvořák. The Cello Concerto in B minor, op.&nbsp;104, B. 191
            </li>
            <li>S.Prokofiev. Sonata for cello and piano op.&nbsp;119</li>
            <li>
                S.Prokofiev. <CiteTitle>Ballade</CiteTitle> op.&nbsp;15 for
                cello and piano
            </li>
        </ul>
        <h2>Ensemble with flute</h2>
        <ul>
            <li>
                J. S. Bach. Sonata No.&nbsp;1 for piano and flute (BWV 1030)
            </li>
            <li>
                F. Schubert. <CiteTitle>Trockne Blumen</CiteTitle> Introduction
                and Variations D802 (op.&nbsp;post. 160)
            </li>
            <li>
                C. Reinecke. <CiteTitle>Undine</CiteTitle> Sonata for flute and
                piano, op.&nbsp;167
            </li>
            <li>
                S. Prokofiev. Sonata No.&nbsp;2 for violin (or flute) and piano,
                op.&nbsp;94
            </li>
            <li>P. Hindemith. Sonata for flute and piano</li>
            <li>P. Juon. Sonata for flute and piano op.&nbsp;78</li>
            <li>R. Muczynski. Sonata for flute and piano op.&nbsp;14</li>
        </ul>
        <h2>Vocal</h2>
        <ul>
            <li>
                Romances, songs and arias by J. S. Bach, W. A. Mozart, M.
                Glinka, R. Schumann, J. Brahms, P. Tchaikovsky, S. Rachmaninoff,
                N. Medtner, R. Strauss
            </li>
            <li>
                S. Prokofiev. <CiteTitle>The Ugly Duckling</CiteTitle>{' '}
                op.&nbsp;18
            </li>
            <li>
                M. Mussorgsky. <CiteTitle>The Nursery</CiteTitle>
            </li>
        </ul>
    </Page>
);

export default RepertoirePage;
